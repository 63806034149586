<template>
  <Browse
    :columns="[
      'name',
      'rate',
      'note',
    ]"
    :formats="{
      rate: 'Rate',
    }"
    itemsPerPage="10"
    :searchOnStart="true"
    table="tax_rates"
  />
</template>

<script>
import Browse from "@/components/Browse.vue";

export default {
  components: {
    Browse,
  },
};
</script>
